import React, { useEffect, useState } from "react";
import {
  Form,
  Switch,
  Button,
  Typography,
  Divider,
  Card,
  Spin,
  message,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { updateFacility } from "../features/facilities/facilitiesSlice"; // Update this path

import "./StoreSettingsForm.css";
const { Title } = Typography;
const StoreSettingsForm = ({ onSave }) => {
  const loading = useSelector((state) => state.facilities.loading);
  const updateStatus = useSelector((state) => state.facilities.updateStatus); // Assuming you have a state to track update status
  const updateError = useSelector((state) => state.facilities.updateError); // Assuming you have a state to track the error message
  const roles = useSelector((state) => state.auth.userRoles);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const selectedFacility = useSelector(
    (state) => state.facilities.selectedFacility
  );
  const selectedFacilitySettings = selectedFacility.settings || {};
  const email = useSelector((state) => state.auth.userEmail);

  const isEditMode = Object.keys(selectedFacilitySettings).length > 0;

  const [settingsState, setSettingsState] = useState({
    rentals: selectedFacilitySettings.rentals,
    waitlist: selectedFacilitySettings.waitlist,
    insurance: selectedFacilitySettings.insurance,
    requireId: selectedFacilitySettings.requireId,
    requireDob: selectedFacilitySettings.requireDob,
    reservations: selectedFacilitySettings.reservations,
  });

  const handleSwitchChange = (name, checked) => {
    const newState = { ...settingsState, [name]: checked };
    setSettingsState(newState);
    form.setFieldsValue({ [name]: checked });
  };

  const onFinish = (values) => {
    const settingsToUpdate = {
      ...values,
      rentals: !!values.rentals,
      waitlist: !!values.waitlist,
      insurance: !!values.insurance,
      requireId: !!values.requireId,
      requireDob: !!values.requireDob,
      reservations: !!values.reservations,
    };

    dispatch(
      updateFacility({
        id: selectedFacility.id,
        data: { facility: { settings: settingsToUpdate } },
        userRoles: roles,
        userEmail: email,
      })
    );

    if (onSave) onSave();
  };

  useEffect(() => {
    if (updateStatus === "success") {
      message.success("Settings updated successfully");
      // Reset update status if needed to prevent duplicate messages
    } else if (updateStatus === "failed") {
      message.error(`Failed to update settings: ${updateError}`);
      // Reset update status if needed to prevent duplicate messages
    }
    // Reset update status logic here if necessary
  }, [updateStatus, updateError]);

  useEffect(() => {
    form.setFieldsValue(settingsState);
  }, [settingsState, form]);

  const isSpecialEmail = (email) => {
    const specialEmails = [
      "JEsbenshade@nsareit.net",
      "agoris@nsareit.net",
      "mhall@nsabrands.com",
      "CWess@nsabrands.com",
      "kperez@nsareit.net",
      "Johnnyalambo@gmail.com",
      "jswanson@nsareit.net",
    ];
    return specialEmails.includes(email);
  };

  return (
    <Card className="store-settings-form">
      {loading ? (
        <Spin size="large" />
      ) : (
        <>
          <Title level={2}>
            {selectedFacility.settings
              ? "Edit Store Settings"
              : "Create Store Settings"}
          </Title>
          <Divider />
          <Title level={4}>Store</Title>
          <div className="store-name">{selectedFacility.name}</div>
          <Title level={4}>Settings</Title>
          <p>Configure the settings for the selected store.</p>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={settingsState}
          >
            <Form.Item label="Rentals" name="rentals" valuePropName="checked">
              Prevent rentals from being created at this store.
              <Switch
                checkedChildren="Rentals Prevented"
                unCheckedChildren="Rentals Allowed"
                checked={settingsState.rentals}
                onChange={(checked) => handleSwitchChange("rentals", checked)}
              />
            </Form.Item>

            <Form.Item
              label="Reservations"
              name="reservations"
              valuePropName="checked"
            >
              Prevent reservations from being created at this store.
              <Switch
                checkedChildren="Reservations Prevented"
                unCheckedChildren="Reservations Allowed"
                checked={settingsState.reservations}
                onChange={(checked) =>
                  handleSwitchChange("reservations", checked)
                }
              />
            </Form.Item>

            <Form.Item label="Waitlist" name="waitlist" valuePropName="checked">
              Prevent waitlist requests from being created at this store.
              <Switch
                checkedChildren="Waitlist Prevented"
                unCheckedChildren="Waitlist Allowed"
                checked={settingsState.waitlist}
                onChange={(checked) => handleSwitchChange("waitlist", checked)}
              />
            </Form.Item>

            <Form.Item
              label="Insurance"
              name="insurance"
              valuePropName="checked"
            >
              Prevent insurance from being added to rentals at this store.
              <Switch
                checkedChildren="Insurance Prevented"
                unCheckedChildren="Insurance Allowed"
                checked={settingsState.insurance}
                onChange={(checked) => handleSwitchChange("insurance", checked)}
              />
            </Form.Item>

            {/* <Form.Item
              label="Require ID"
              name="requireId"
              valuePropName="checked"
            >
              Require identification information from customers for rentals at
              this store.
              <Switch
                checkedChildren="ID Required"
                unCheckedChildren="ID Not Required"
                checked={settingsState.requireId}
                onChange={(checked) => handleSwitchChange("requireId", checked)}
              />
            </Form.Item> */}

            {/* <Form.Item
              label="Require DOB"
              name="requireDob"
              valuePropName="checked"
            >
              Require a date of birth from customers for rentals at this store.
              <Switch
                checkedChildren="DOB Required"
                unCheckedChildren="DOB Not Required"
                checked={settingsState.requireDob}
                onChange={(checked) =>
                  handleSwitchChange("requireDob", checked)
                }
              />
            </Form.Item> */}
                      <Button
              type="primary"
              htmlType="submit"
              disabled={!roles.includes('admin') || loading}
            >
              {isEditMode ? "Update" : "Save"}
            </Button>

          </Form>
        </>
      )}
    </Card>
  );
};

export default StoreSettingsForm;
