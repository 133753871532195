import React, { useEffect, useState } from "react";
import {
  Descriptions,
  Divider,
  Card,
  Progress,
  Button,
  Form,
  Input,
  Radio,
  DatePicker,
  notification,
  Spin,
} from "antd";
import "./facilityDisplayStyles.css";
import { useSelector } from "react-redux";
import moment from "moment";
const { TextArea } = Input;

const SurveyComponent = () => {
  const microMind = process.env.REACT_APP_MICRO_API_URL;

  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectedFacility = useSelector(
    (state) => state.facilities.selectedFacility
  );

  const userRole = useSelector((state) => state.auth.userRoles);
  const userEmail = useSelector((state) => state.auth.userEmail);
  const mmApiKey = useSelector((state) => state.auth.mmApiKey);

  // Initial steps with detailed questions
  const initialSteps = [
    {
      title: "Step 1",
      questions: [
        {
          questionId: "GoogleDirections",
          questionText: (
            <>
              Prior to arrival: Search for location in{" "}
              <a
                href="https://maps.google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Maps
              </a>{" "}
              & click get directions. Does this bring you to the property entrance/office?
            </>
          ),
          followUp: {
            questionText:
              "Were you brought to the wrong address altogether, or was the map marker just not in the right spot on the property, where you were brought to the wrong property entrance?",
          },
        },
      ],
    },
    {
      title: "Step 2",
      questions: [
        {
          questionId: "OfficeHours",
          questionText:
            "Are office hours consistent and accurate across platforms (website, Google, Megamind, etc)?",
          followUp: {
            questionText:
              "What platform or info told you the store should be open?",
          },
        },
        {
          questionId: "OfficeClosed",
          questionText: "Was the Office Operating in Designated Open Hours?",
        },
        {
          questionId: "ClosedSignage",
          questionText:
            "If closed, is there an NSA approved sign on the door clearly indicating closed status and provide customer direction for assistance?",
        },
      ],
    },
    {
      title: "Step 3",
      questions: [
        {
          questionId: "FirstImpression",
          questionText:
            "Is the parking lot, entrance, landscaping, trash enclosure clean and well kept?",
          followUp: {
            questionText: "What about the first impression was poor?",
          },
        },
      ],
    },
    {
      title: "Step 4",
      questions: [
        {
          questionId: "DoorDecals",
          questionText:
            "Does the first look on the front door and window meet NSA's brand expectations? When relevant, is the two-sided clock/sorry we missed you sign hanging?",
          followUp: {
            questionText: "What signs were and were not hanging?",
          },
        },
      ],
    },
  ];

  // Additional steps if user can enter property
  const additionalSteps = [
    {
      title: "Step 6",
      questions: [
        {
          questionId: "OnPropertySignage",
          questionText:
            "Are the signs on property consistent with the NSA approved sign requirements?",
          followUp: {
            questionText:
              "What signage was found that doesn't meet brand standards?",
          },
        },
        {
          questionId: "SignageCondition",
          questionText:
            "Is the signage on the property visible, lit (where applicable), and in good condition?",
          followUp: {
            questionText: "What about the store signage was poor?",
          },
        },
      ],
    },
    {
      title: "Step 7",
      questions: [
        {
          questionId: "OfficeStandards",
          questionText:
            "Is the office Clean/Organized/Clutter Free/No personal items? And is the restroom is clean and stocked?",
          followUp: {
            questionText: "What about the office standards were poor?",
          },
        },
      ],
    },
    {
      title: "Step 8",
      questions: [
        {
          questionId: "Merchandise",
          questionText:
            "Is merchandise signage consistent with NSA branding, and is merchandise properly arranged, neat, and well stocked?",
          followUp: {
            questionText: "What about the merchandise display was poor?",
          },
        },
      ],
    },
    {
      title: "Step 9",
      questions: [
        {
          questionId: "DriveAisles/Hallways",
          questionText:
            "Are drive aisles and hallways clean and free of debri (doors, walls, floors clean)?",
          followUp: {
            questionText:
              "What about the drive aisles, hallways were poor?",
          },
        },
        {
          questionId: "Lighting",
          questionText:
            "Are interior lights operational, with no burned out bulbs, and if applicable, are exterior lights functional?",
          followUp: {
            questionText: "What about the lighting was poor?",
          },
        },
      ],
    },
    {
      title: "Step 10",
      questions: [
        {
          questionId: "Exit/Fire",
          questionText:
            "Are exit and emergency access points operational, with appropriate signage in place where needed?",
          followUp: {
            questionText:
              "What about the safety points of the property are concerning?",
          },
        },
      ],
    },
    {
      title: "Step 11",
      questions: [
        {
          questionId: "Employee",
          questionText:
            "Was the employee's uniform/attire well presented and within NSA standards?",
        },
      ],
    },
  ];

  // Function to calculate progress
  const calculateProgress = () => {
    // total steps excluding completion
    const totalSteps = steps.length - 1;
    const progress = Math.floor(((current + 1) / totalSteps) * 100);
    return Math.min(progress, 100);
  };

  const getCompletionStep = () => ({
    title: "Completion",
    key: "completion",
    content: (
      <>
        <h2>Thank you for completing the survey!</h2>
        <Button type="primary" onClick={retakeSurvey}>
          Retake Survey
        </Button>
      </>
    ),
  });

  const retakeSurvey = () => {
    form.resetFields();
    setFormData({});
    setCurrent(0);
    initializeSteps();
  };

  const initializeSteps = () => {
    let overallQuestionIndex = 0;

    // Process initial steps
    const processedSteps = initialSteps.map((step, stepIndex) => {
      const content = (
        <>
          {step.questions.map((question, questionIndex) => {
            overallQuestionIndex++;
            const radioFieldName = `question_${question.questionId}_answer`;
            const textFieldName = `question_${question.questionId}_text`;
            const followUpFieldName = `question_${question.questionId}_followUp`;

            return (
              <div key={`${stepIndex}_${questionIndex}`}>
                {/* Question Title */}
                <Form.Item>
                  <strong>{question.questionText}</strong>
                </Form.Item>

                {/* Yes/No Radio Button */}
                <Form.Item
                  name={radioFieldName}
                  rules={[{ required: true, message: "Please select Yes or No" }]}
                >
                  <Radio.Group>
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>

                {/* Conditional Text Box */}
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues[radioFieldName] !== currentValues[radioFieldName]
                  }
                >
                  {({ getFieldValue }) => {
                    const answer = getFieldValue(radioFieldName);
                    if (answer === "Yes") {
                      return (
                        <Form.Item name={textFieldName} label="Additional Comments">
                          <TextArea rows={3} placeholder="Optional comments..." />
                        </Form.Item>
                      );
                    } else if (answer === "No") {
                      if (question.followUp) {
                        return (
                          <Form.Item
                            name={followUpFieldName}
                            label={question.followUp.questionText}
                            rules={[
                              {
                                required: true,
                                message: "This field is required",
                              },
                            ]}
                          >
                            <TextArea
                              rows={3}
                              placeholder="Please provide details..."
                            />
                          </Form.Item>
                        );
                      } else {
                        return (
                          <Form.Item
                            name={textFieldName}
                            label="Please explain"
                            rules={[
                              {
                                required: true,
                                message:
                                  "This field is required when you select No",
                              },
                            ]}
                          >
                            <TextArea rows={3} placeholder="Required comments..." />
                          </Form.Item>
                        );
                      }
                    }
                    return null;
                  }}
                </Form.Item>

                <Divider />
              </div>
            );
          })}
        </>
      );

      return {
        title: step.title,
        key: `initialStep_${stepIndex + 1}`,
        content: content,
      };
    });

    // Add the entry question step
    overallQuestionIndex++;
    const entryStep = {
      title: `Entry Question`,
      key: "entryQuestion",
      content: (
        <>
          <Form.Item
            name="entryAnswer"
            label="Were you able to enter the property?"
            rules={[{ required: true, message: "Please select Yes or No" }]}
          >
            <Radio.Group>
              <Radio value="Yes">Yes</Radio>
              <Radio value="No">No</Radio>
            </Radio.Group>
          </Form.Item>
        </>
      ),
    };

    // Initialize steps array with processed steps, entry question and completion
    setSteps([...processedSteps, entryStep, getCompletionStep()]);
  };

  useEffect(() => {
    initializeSteps();
  }, []);

  const submitSurveyData = async (data) => {
    try {
      const microMind = process.env.REACT_APP_MICRO_API_URL;
      const microMindKey = process.env.REACT_APP_MICRO_KEY;
      const response = await fetch(`${microMind}/api/submit-survey`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "x-api-key": microMindKey,
          "Content-Type": "application/json",
          Authorization: `Bearer ${mmApiKey}`,
        },
        credentials: "same-origin",
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || "Failed to submit survey. Please try again."
        );
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error submitting survey data:", error);
      throw new Error(
        error.message || "Failed to submit survey. Please try again."
      );
    }
  };

  const onFinish = (finalData = formData) => {
    form
      .validateFields()
      .then((values) => {
        // Set visitDateTime to the current time in ISO format
        const visitDateTime = new Date().toISOString();
        const mmId = selectedFacility?.id || "default-facility-id";
        const nsaId = selectedFacility?.nsa_id;
        const site_lat = selectedFacility?.latitude
        const site_long = selectedFacility?.longitude

        let mergedData = {
          ...finalData,
          ...values,
          visitDateTime,
          mmId,
          nsaId,
          site_lat,
          site_long
        };

        // Retrieve userLocation from localStorage
        const userLocationString = localStorage.getItem("userLocation");
        mergedData.userEmail = userEmail;
        let parsedUserLocation = null;

        if (userLocationString) {
          try {
            parsedUserLocation = JSON.parse(userLocationString);
          } catch (error) {
            console.error("Error parsing userLocation:", error);
          }
        }

        // Add userLocation
        if (parsedUserLocation) {
          mergedData.user_lat = parsedUserLocation.lat
          mergedData.user_long = parsedUserLocation.lon

        } else {
          // mergedData.userLocation = "not available";
          mergedData.user_lat = "not available"
          mergedData.user_long =  "not available"
        }

        // Replace undefined/null values with empty strings except userLocation
        const replaceUndefinedValues = (obj) => {
          Object.keys(obj).forEach((key) => {
            if (obj[key] === undefined || obj[key] === null) {
              if (key !== "user_lat" ||key !== "user_long") {
                obj[key] = "";
              }
            } else if (typeof obj[key] === "object" && obj[key] !== null) {
              if (key !== "user_lat" ||key !== "user_long") {
                replaceUndefinedValues(obj[key]);
              }
            }
          });
        };

        replaceUndefinedValues(mergedData);

        setLoading(true);

        submitSurveyData(mergedData)
          .then((response) => {
            notification.success({
              message: "Success",
              description: "Your survey has been submitted!",
            });
            // Move to completion step
            setCurrent(steps.length - 1);
            setLoading(false);
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error.message,
            });
            setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  const next = () => {
    form
      .validateFields()
      .then((values) => {
        const updatedFormData = { ...formData, ...values };
        setFormData(updatedFormData);

        const currentKey = steps[current]?.key;

        if (currentKey === "entryQuestion") {
          const entryAnswer = values.entryAnswer;
          if (entryAnswer === "Yes") {
            // User can enter, add additional steps before completion
            let overallQuestionIndex = Object.keys(updatedFormData).filter((key) =>
              key.includes("question_")
            ).length;

            const additionalProcessedSteps = additionalSteps.map((step, index) => {
              const content = (
                <>
                  {step.questions.map((question, questionIndex) => {
                    overallQuestionIndex++;
                    const radioFieldName = `question_${question.questionId}_answer`;
                    const textFieldName = `question_${question.questionId}_text`;
                    const followUpFieldName = `question_${question.questionId}_followUp`;

                    return (
                      <div key={`additional_${index}_${questionIndex}`}>
                        <Form.Item>
                          <strong>{question.questionText}</strong>
                        </Form.Item>

                        <Form.Item
                          name={radioFieldName}
                          rules={[
                            { required: true, message: "Please select Yes or No" },
                          ]}
                        >
                          <Radio.Group>
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                          </Radio.Group>
                        </Form.Item>

                        <Form.Item
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues[radioFieldName] !==
                            currentValues[radioFieldName]
                          }
                        >
                          {({ getFieldValue }) => {
                            const answer = getFieldValue(radioFieldName);
                            if (answer === "Yes") {
                              return (
                                <Form.Item
                                  name={textFieldName}
                                  label="Additional Comments"
                                >
                                  <Input.TextArea
                                    rows={3}
                                    placeholder="Optional comments..."
                                  />
                                </Form.Item>
                              );
                            } else if (answer === "No") {
                              if (question.followUp) {
                                return (
                                  <Form.Item
                                    name={followUpFieldName}
                                    label={question.followUp.questionText}
                                    rules={[
                                      {
                                        required: true,
                                        message: "This field is required",
                                      },
                                    ]}
                                  >
                                    <Input.TextArea
                                      rows={3}
                                      placeholder="Please provide details..."
                                    />
                                  </Form.Item>
                                );
                              } else {
                                return (
                                  <Form.Item
                                    name={textFieldName}
                                    label="Please explain"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "This field is required when you select No",
                                      },
                                    ]}
                                  >
                                    <Input.TextArea
                                      rows={3}
                                      placeholder="Required comments..."
                                    />
                                  </Form.Item>
                                );
                              }
                            }
                            return null;
                          }}
                        </Form.Item>

                        <Divider />
                      </div>
                    );
                  })}
                </>
              );

              return {
                title: `Step ${steps.length + index}`,
                key: `additionalStep_${index + 1}`,
                content: content,
              };
            });

            // Replace the current steps after entryQuestion with additional steps + completion
            setSteps((prevSteps) => {
              const stepsWithoutLast = prevSteps.slice(0, current + 1);
              return [...stepsWithoutLast, ...additionalProcessedSteps, getCompletionStep()];
            });

            setCurrent(current + 1);
            form.resetFields();
          } else {
            // User could not enter, submit immediately
            onFinish(updatedFormData);
          }
        } else {
          // If not on entry question step:
          // Check if this is last question step (before completion)
          if (current === steps.length - 2 && steps[steps.length - 1].key === "completion") {
            // Submit here
            onFinish(updatedFormData);
          } else {
            // Move to next step
            setCurrent(current + 1);
            form.resetFields();
          }
        }
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  const prev = () => {
    setCurrent(current - 1);
    form.setFieldsValue(formData);
  };

  // If steps are not initialized yet
  if (steps.length === 0) {
    return (
      <div style={{ textAlign: "center", marginTop: 50 }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ padding: "0 16px" }}>
      <Progress percent={calculateProgress()} />

      <div className="steps-content" style={{ marginTop: 24 }}>
        <Form form={form} layout="vertical">
          {steps[current] && steps[current].content}
        </Form>
      </div>
      <div className="steps-action" style={{ marginTop: 24 }}>
        {current > 0 && steps[current]?.key !== "completion" && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
        )}
        {steps[current] && steps[current].key !== "completion" && (
          <Button type="primary" onClick={() => next()} loading={loading}>
            Next
          </Button>
        )}
      </div>
    </div>
  );
};

const StoreAuditDisplay = () => {
  const selectedFacility = useSelector(
    (state) => state.facilities.selectedFacility
  );

  const { address, name } = selectedFacility || {};

  return (
    <>
      <Card title={name || "Loading..."}>
        <Descriptions title="Store Audit" column={3} bordered={false}>
          <Descriptions.Item label="Address">{address}</Descriptions.Item>
        </Descriptions>

        <Divider />

        <SurveyComponent />
      </Card>
    </>
  );
};

export default StoreAuditDisplay;
