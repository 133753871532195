// OpsAlignmentFacilitiesTable.jsx


import React, { useEffect, useState, useRef, useCallback,useContext } from 'react';
import {
  Table,
  Button,
  Input,
  Spin,
  Card,
  Space,
  Form,
  InputNumber,
  Popconfirm,
  message,
} from 'antd';
import {
  fetchFacilityDetails,
  updateFacility,
  updateSelectedFacilityDetails,
} from '../features/facilities/facilitiesSlice';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import * as XLSX from 'xlsx';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as unitsSlice from '../features/units/unitsSlice';
import * as engrainSlice from '../features/engrain/engrainSlice';
import { cloneDeep } from 'lodash';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };


  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
  
    // Helper function to get nested value
    const getNestedValue = (obj, path) => {
      return path.reduce((acc, key) => (acc ? acc[key] : undefined), obj);
    };
  
    // Helper function to set nested value
    const setNestedValue = (obj, path, value) => {
      let current = obj;
      for (let i = 0; i < path.length - 1; ++i) {
        if (!current[path[i]]) {
          current[path[i]] = {};
        }
        current = current[path[i]];
      }
      current[path[path.length - 1]] = value;
    };
  
    useEffect(() => {
      if (editing) {
        inputRef.current?.focus();
      }
    }, [editing]);
  
    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: getNestedValue(record, Array.isArray(dataIndex) ? dataIndex : [dataIndex]),
      });
    };
  
    const save = async () => {
        try {
          const values = await form.validateFields();
          toggleEdit();
    
          const updatedRecord = cloneDeep(record);
    
          setNestedValue(
            updatedRecord,
            Array.isArray(dataIndex) ? dataIndex : [dataIndex],
            getNestedValue(values, Array.isArray(dataIndex) ? dataIndex : [dataIndex])
          );
          handleSave(updatedRecord);
        } catch (errInfo) {
          console.log('Save failed:', errInfo);
        }
      };
  
    const name = Array.isArray(dataIndex) ? dataIndex : [dataIndex];
  
    let childNode = children;
  
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={name}
          rules={[
            {
              required: false,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{ paddingInlineEnd: 24 }}
          onClick={(e) => {
            e.stopPropagation();
            toggleEdit();
          }}
        >
          {children}
        </div>
      );
    }
  
    return <td {...restProps}>{childNode}</td>;
  };
  
  
  
  

const OpsAlignmentFacilitiesTable = () => {
  const [facilityDetailsLoading, setFacilityDetailsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [editingKey, setEditingKey] = useState('');
  const [updatingKey, setUpdatingKey] = useState('');
  const [form] = Form.useForm();
  const searchInput = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Select necessary state from Redux store
  const userRoles = useSelector((state) => state.auth.userRoles);
  const userEmail = useSelector((state) => state.auth.userEmail);
  const biFacilities = useSelector((state) => state.facilities.biFacilities);
  
  const loading = useSelector((state) => state.facilities.loading);

  // Debugging: Log updates to biFacilities
  useEffect(() => {
    console.log('biFacilities Updated:', biFacilities);
  }, [biFacilities]);

  const isEditing = (record) => record.id === editingKey;

  const edit = useCallback(
    (record) => {
      form.setFieldsValue({
        nsa_id: '',
        original_id: '',
        ...record,
      });
      setEditingKey(record.id);
    },
    [form]
  );

  const cancel = useCallback(() => {
    setEditingKey('');
  }, []);

const handleSave = async (row) => {
  try {
    // Dispatch the updateFacility action
    await dispatch(
      updateFacility({
        id: row.id,
        data: { facility: row },
        userRoles,
        userEmail,
      })
    ).unwrap();

    message.success('Facility updated successfully');
  } catch (error) {
    console.error('Failed to update facility:', error);
    message.error(`Failed to update facility: ${error.message || 'Unknown error'}`);
  }
};

  
  

  const handleView = useCallback(
    async (record) => {
      if (editingKey !== '') return; // Prevent navigation during editing
      setFacilityDetailsLoading(true);

      try {
        const facilityDetailsResponse = await dispatch(
          fetchFacilityDetails({
            brand: record.brand,
            id: record.id,
            cookie: document.cookie,
          })
        ).unwrap();
        dispatch(updateSelectedFacilityDetails(facilityDetailsResponse));

        await dispatch(engrainSlice.fetchUnits());
        await dispatch(engrainSlice.fetchFloors());
        await dispatch(unitsSlice.fetchUnits(record.id));

        setFacilityDetailsLoading(false);
        navigate(`/facilities/${record.id}`);
      } catch (error) {
        console.error('Error fetching facility details:', error);
        setFacilityDetailsLoading(false);
      }
    },
    [dispatch, editingKey, navigate]
  );

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
    setSearchedColumn('');
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(Array.isArray(dataIndex) ? dataIndex.join('.') : dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${Array.isArray(dataIndex) ? dataIndex.join('.') : dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? '#1890ff' : undefined }}
      />
    ),
    onFilter: (value, record) => {
      const recordValue = getNestedValue(
        record,
        Array.isArray(dataIndex) ? dataIndex : [dataIndex]
      );
      return recordValue
        ? recordValue.toString().toLowerCase().includes(value.toLowerCase())
        : '';
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchText && dataIndex === searchedColumn ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  // Helper function to get nested values
  const getNestedValue = (obj, path) => {
    return path.reduce((acc, key) => (acc && acc[key] ? acc[key] : null), obj);
  };

  const padNSAId = (id) => {
    if (!id || id.toString().length === 0) {
      return id;
    }
    return id.toString().padStart(4, '0');
  };

  const getOriginalId = (originalId, nsaId) => {
    if (originalId == null && nsaId) {
      return padNSAId(nsaId);
    }
    return padNSAId(originalId);
  };

  const columns = [
    {
      title: 'NSA Store #',
      width: 100,
      dataIndex: 'nsa_id',
      key: 'nsa_id',
      sorter: (a, b) =>
        (a.nsa_id ?? '').toString().localeCompare((b.nsa_id ?? '').toString()),
      ...getColumnSearchProps('nsa_id'),
      render: (text) => padNSAId(text),
      editable: true,
    },
    {
      title: 'Original ID',
      width: 150,
      dataIndex: 'original_id',
      key: 'original_id',
      sorter: (a, b) =>
        (a.original_id ? a.original_id.toString() : '').localeCompare(
          b.original_id ? b.original_id.toString() : ''
        ),
      ...getColumnSearchProps('original_id'),
      render: (text, record) => getOriginalId(text, record.nsa_id),
      editable: true,
    },
    {
      title: 'Brand Name',
      width: 100,
      dataIndex: 'brand_name',
      key: 'brand_name',
      sorter: (a, b) =>
        (a.brand_name ?? '').toString().localeCompare(
          (b.brand_name ?? '').toString()
        ),
      ...getColumnSearchProps('brand_name'),
    },
    {
      title: 'Annex Site',
      width: 100,
      dataIndex: ['custom', 'annexSite'],
      key: 'custom.annexSite',
      sorter: (a, b) =>
        (a.custom?.annexSite ?? '').localeCompare(b.custom?.annexSite ?? ''),
      ...getColumnSearchProps(['custom', 'annexSite']),
      editable: true,
    },
    {
      title: 'Annex Site #',
      width: 100,
      dataIndex: ['custom', 'annex_site_number'],
      key: 'custom.annex_site_number',
      sorter: (a, b) =>
        (a.custom?.annex_site_number ?? '').localeCompare(
          b.custom?.annex_site_number ?? ''
        ),
      ...getColumnSearchProps(['custom', 'annex_site_number']),
      editable: true,
    },
    {
      title: 'MSA',
      width: 100,
      dataIndex: ['custom', 'msa'],
      key: 'custom.msa',
      sorter: (a, b) =>
        (a.custom?.msa ?? '').localeCompare(b.custom?.msa ?? ''),
      ...getColumnSearchProps(['custom', 'msa']),
      editable: true,
    },
    // Remove duplicate 'MSA' column (if any)
    {
      title: 'Site Address',
      width: 100,
      dataIndex: 'address',
      key: 'address',
      sorter: (a, b) => (a.address ?? '').localeCompare(b.address ?? ''),
      ...getColumnSearchProps('address'),
      editable: true,
    },
    {
      title: 'City',
      width: 100,
      dataIndex: 'city',
      key: 'city',
      sorter: (a, b) => (a.city ?? '').localeCompare(b.city ?? ''),
      ...getColumnSearchProps('city'),
      editable: true,
    },
    {
      title: 'State',
      width: 100,
      dataIndex: 'state',
      key: 'state',
      sorter: (a, b) => (a.state ?? '').localeCompare(b.state ?? ''),
      ...getColumnSearchProps('state'),
      editable: true,
    },
    {
      title: 'Zip Code',
      width: 100,
      dataIndex: 'zip_code',
      key: 'zip_code',
      sorter: (a, b) => (a.zip_code ?? '').localeCompare(b.zip_code ?? ''),
      ...getColumnSearchProps('zip_code'),
      editable: true,
    },
    {
      title: 'Direct Phone #',
      width: 100,
      dataIndex: 'phone_number',
      key: 'phone_number',
      sorter: (a, b) => (a.phone_number ?? '').localeCompare(b.phone_number ?? ''),
      ...getColumnSearchProps('phone_number'),
      editable: true,
    },
    {
      title: 'Store Cell Phone #',
      width: 100,
      dataIndex: ['custom', 'store_cell_phone'],
      key: 'custom.store_cell_phone',
      sorter: (a, b) =>
        (a.custom?.store_cell_phone ?? '').localeCompare(b.custom?.store_cell_phone ?? ''),
      ...getColumnSearchProps(['custom', 'store_cell_phone']),
      editable: true,
    },
    {
      title: 'Store Email Address',
      width: 100,
      dataIndex: 'email_address',
      key: 'email_address',
      sorter: (a, b) =>
        (a.email_address ?? '').localeCompare(b.email_address ?? ''),
      ...getColumnSearchProps('email_address'),
      editable: true,
    },
    {
      title: 'West or East Division',
      width: 150,
      dataIndex: ['custom', 'divisionGeography'],
      key: 'custom.divisionGeography',
      sorter: (a, b) =>
        (a.custom?.divisionGeography ?? '').localeCompare(b.custom?.divisionGeography ?? ''),
      ...getColumnSearchProps(['custom', 'divisionGeography']),
      editable: true,
    },
    {
      title: 'Division #',
      width: 150,
      dataIndex: ['custom', 'divisionNumber'],
      key: 'custom.divisionNumber',
      sorter: (a, b) =>
        (a.custom?.divisionNumber ?? '').localeCompare(b.custom?.divisionNumber ?? ''),
      ...getColumnSearchProps(['custom', 'divisionNumber']),
      editable: true,
    },
    {
      title: 'Division Director',
      width: 150,
      dataIndex: ['custom', 'division_director'],
      key: 'custom.division_director',
      sorter: (a, b) =>
        (a.custom?.division_director ?? '').localeCompare(b.custom?.division_director ?? ''),
      ...getColumnSearchProps(['custom', 'division_director']),
      editable: true,
    },
    {
      title: 'Region #',
      width: 100,
      dataIndex: ['custom', 'regionNumber'],
      key: 'custom.regionNumber',
      sorter: (a, b) =>
        (a.custom?.regionNumber ?? '').localeCompare(b.custom?.regionNumber ?? ''),
      ...getColumnSearchProps(['custom', 'regionNumber']),
      editable: true,
    },
    {
      title: 'Regional Manager',
      width: 150,
      dataIndex: ['custom', 'regionalManager'],
      key: 'custom.regionalManager',
      sorter: (a, b) =>
        (a.custom?.regionalManager ?? '').localeCompare(b.custom?.regionalManager ?? ''),
      ...getColumnSearchProps(['custom', 'regionalManager']),
      editable: true,
    },
    {
      title: 'Regional Phone #',
      width: 150,
      dataIndex: ['custom', 'regionalManagerPhoneNumber'],
      key: 'custom.regionalManagerPhoneNumber',
      sorter: (a, b) =>
        (a.custom?.regionalManagerPhoneNumber ?? '').localeCompare(b.custom?.regionalManagerPhoneNumber ?? ''),
      ...getColumnSearchProps(['custom', 'regionalManagerPhoneNumber']),
      editable: true,
    },
    {
      title: 'Regional Manager Email Address',
      width: 150,
      dataIndex: ['custom', 'regionalManagerEmail'],
      key: 'custom.regionalManagerEmail',
      sorter: (a, b) =>
        (a.custom?.regionalManagerEmail ?? '').localeCompare(b.custom?.regionalManagerEmail ?? ''),
      ...getColumnSearchProps(['custom', 'regionalManagerEmail']),
      editable: true,
    },
    {
      title: 'Area #',
      width: 100,
      dataIndex: ['custom', 'areaNumber'],
      key: 'custom.areaNumber',
      sorter: (a, b) =>
        (a.custom?.areaNumber ?? '').localeCompare(b.custom?.areaNumber ?? ''),
      ...getColumnSearchProps(['custom', 'areaNumber']),
      editable: true,
    },
    {
      title: 'Area Manager',
      width: 150,
      dataIndex: ['custom', 'areaManager'],
      key: 'custom.areaManager',
      sorter: (a, b) =>
        (a.custom?.areaManager ?? '').localeCompare(b.custom?.areaManager ?? ''),
      ...getColumnSearchProps(['custom', 'areaManager']),
      editable: true,
    },
    {
      title: 'AM Phone #',
      width: 100,
      dataIndex: ['custom', 'areaManagerPhoneNumber'],
      key: 'custom.areaManagerPhoneNumber',
      sorter: (a, b) =>
        (a.custom?.areaManagerPhoneNumber ?? '').localeCompare(b.custom?.areaManagerPhoneNumber ?? ''),
      ...getColumnSearchProps(['custom', 'areaManagerPhoneNumber']),
      editable: true,
    },
    {
      title: 'AM Email Address',
      width: 150,
      dataIndex: ['custom', 'areaManagerEmail'],
      key: 'custom.areaManagerEmail',
      sorter: (a, b) =>
        (a.custom?.areaManagerEmail ?? '').localeCompare(b.custom?.areaManagerEmail ?? ''),
      ...getColumnSearchProps(['custom', 'areaManagerEmail']),
      editable: true,
    },
    {
      title: 'Unit Count',
      width: 100,
      dataIndex: ['custom', 'units'],
      key: 'custom.units',
      sorter: (a, b) =>
        (a.custom?.units ?? '').toString().localeCompare((b.custom?.units ?? '').toString()),
      ...getColumnSearchProps(['custom', 'units']),
      editable: true,
    },
    {
      title: 'Property Square Footage',
      width: 150,
      dataIndex: ['custom', 'totalSqFt'],
      key: 'custom.totalSqFt',
      sorter: (a, b) =>
        (a.custom?.totalSqFt ?? '').toString().localeCompare((b.custom?.totalSqFt ?? '').toString()),
      ...getColumnSearchProps(['custom', 'totalSqFt']),
      editable: true,
    },
    {
      title: 'County',
      width: 150,
      dataIndex: ['custom', 'county'],
      key: 'custom.county',
      sorter: (a, b) =>
        (a.custom?.county ?? '').localeCompare(b.custom?.county ?? ''),
      ...getColumnSearchProps(['custom', 'county']),
      editable: true,
    },
    {
      title: 'Commercial Lease Title Holder',
      width: 150,
      dataIndex: ['custom', 'commercial_Lease_Title_Holder'],
      key: 'custom.commercial_Lease_Title_Holder',
      sorter: (a, b) =>
        (a.custom?.commercial_Lease_Title_Holder ?? '').localeCompare(b.custom?.commercial_Lease_Title_Holder ?? ''),
      ...getColumnSearchProps(['custom', 'commercial_Lease_Title_Holder']),
      editable: true,
    },
    {
      title: 'Partnership',
      width: 150,
      dataIndex: ['custom', 'partnership'],
      key: 'custom.partnership',
      sorter: (a, b) =>
        (a.custom?.partnership ?? '').localeCompare(b.custom?.partnership ?? ''),
      ...getColumnSearchProps(['custom', 'partnership']),
      editable: true,
    },
    {
      title: 'Company Code',
      width: 150,
      dataIndex: ['custom', 'companyCode'],
      key: 'custom.companyCode',
      sorter: (a, b) =>
        (a.custom?.companyCode ?? '').localeCompare(b.custom?.companyCode ?? ''),
      ...getColumnSearchProps(['custom', 'companyCode']),
      editable: true,
    },
    {
      title: 'Entity Code',
      width: 100,
      dataIndex: ['custom', 'entityCode'],
      key: 'custom.entityCode',
      sorter: (a, b) =>
        (a.custom?.entityCode ?? '').localeCompare(b.custom?.entityCode ?? ''),
      ...getColumnSearchProps(['custom', 'entityCode']),
      editable: true,
    },
    {
      title: 'Acquisition Date',
      width: 150,
      dataIndex: ['custom', 'acquisitionDate'],
      key: 'custom.acquisitionDate',
      sorter: (a, b) =>
        (a.custom?.acquisitionDate ?? '').localeCompare(b.custom?.acquisitionDate ?? ''),
      ...getColumnSearchProps(['custom', 'acquisitionDate']),
      editable: true,
    },
    {
      title: 'Pricing Analyst',
      width: 150,
      dataIndex: ['custom', 'rmAnalyst'],
      key: 'custom.rmAnalyst',
      sorter: (a, b) =>
        (a.custom?.rmAnalyst ?? '').localeCompare(b.custom?.rmAnalyst ?? ''),
      ...getColumnSearchProps(['custom', 'rmAnalyst']),
      editable: true,
    },
    {
      title: 'People Ops Partner',
      width: 150,
      dataIndex: ['custom', 'people_Ops_Partner'],
      key: 'custom.people_Ops_Partner',
      sorter: (a, b) =>
        (a.custom?.people_Ops_Partner ?? '').localeCompare(b.custom?.people_Ops_Partner ?? ''),
      ...getColumnSearchProps(['custom', 'people_Ops_Partner']),
      editable: true,
    },
    {
      title: 'Recruiting Partner',
      width: 150,
      dataIndex: ['custom', 'recruitingPartner'],
      key: 'custom.recruitingPartner',
      sorter: (a, b) =>
        (a.custom?.recruitingPartner ?? '').localeCompare(b.custom?.recruitingPartner ?? ''),
      ...getColumnSearchProps(['custom', 'recruitingPartner']),
      editable: true,
    },
    {
      title: 'Accounting Partner',
      width: 150,
      dataIndex: ['custom', 'accounting_Partner'],
      key: 'custom.accounting_Partner',
      sorter: (a, b) =>
        (a.custom?.accounting_Partner ?? '').localeCompare(b.custom?.accounting_Partner ?? ''),
      ...getColumnSearchProps(['custom', 'accounting_Partner']),
      editable: true,
    },
    {
      title: 'Facilities Coordinator',
      width: 150,
      dataIndex: ['custom', 'facilities_coordinator'],
      key: 'custom.facilities_coordinator',
      sorter: (a, b) =>
        (a.custom?.facilities_coordinator ?? '').localeCompare(b.custom?.facilities_coordinator ?? ''),
      ...getColumnSearchProps(['custom', 'facilities_coordinator']),
      editable: true,
    },
    {
      title: 'Apartment on Site',
      width: 150,
      dataIndex: ['custom', 'apartmentOnSite'],
      key: 'custom.apartmentOnSite',
      sorter: (a, b) =>
        (a.custom?.apartmentOnSite ?? '').localeCompare(b.custom?.apartmentOnSite ?? ''),
      ...getColumnSearchProps(['custom', 'apartmentOnSite']),
      editable: true,
    },
    {
      title: 'Commercial Office Spaces',
      width: 150,
      dataIndex: ['custom', 'commercial_office_spaces'],
      key: 'custom.commercial_office_spaces',
      sorter: (a, b) =>
        (a.custom?.commercial_office_spaces ?? '').localeCompare(b.custom?.commercial_office_spaces ?? ''),
      ...getColumnSearchProps(['custom', 'commercial_office_spaces']),
      editable: true,
    },
    {
      title: 'Billboard',
      width: 150,
      dataIndex: ['custom', 'billboard'],
      key: 'custom.billboard',
      sorter: (a, b) =>
        (a.custom?.billboard ?? '').localeCompare(b.custom?.billboard ?? ''),
      ...getColumnSearchProps(['custom', 'billboard']),
      editable: true,
    },
    {
      title: 'Cell Tower',
      width: 150,
      dataIndex: ['custom', 'cell_tower'],
      key: 'custom.cell_tower',
      sorter: (a, b) =>
        (a.custom?.cell_tower ?? '').localeCompare(b.custom?.cell_tower ?? ''),
      ...getColumnSearchProps(['custom', 'cell_tower']),
      editable: true,
    },
    {
      title: 'SSM Conversion Date',
      width: 150,
      dataIndex: ['custom', 'ssmConversionDate'],
      key: 'custom.ssmConversionDate',
      sorter: (a, b) =>
        (a.custom?.ssmConversionDate ?? '').localeCompare(b.custom?.ssmConversionDate ?? ''),
      ...getColumnSearchProps(['custom', 'ssmConversionDate']),
    },
    {
      title: 'Unique Property Details',
      width: 150,
      dataIndex: ['custom', 'anythingUniqueAboutThisFacilityOrItsOfferings'],
      key: 'custom.anythingUniqueAboutThisFacilityOrItsOfferings',
      sorter: (a, b) =>
        (a.custom?.anythingUniqueAboutThisFacilityOrItsOfferings ?? '').localeCompare(b.custom?.anythingUniqueAboutThisFacilityOrItsOfferings ?? ''),
      ...getColumnSearchProps(['custom', 'anythingUniqueAboutThisFacilityOrItsOfferings']),
      editable: true,
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      key: 'operation',
      width: 110,
      render: (_, record) => (
        <Space size="middle">
          <a
            href="#"
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering row's onClick
              handleView(record);
            }}
          >
            View
          </a>
        </Space>
      ),
    },
  ];
  

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  

  
  

  const handleExport = () => {
    const excelData = biFacilities.map(record => {
      const customFields = record.custom || {};
      return {
        'NSA Store #': padNSAId(record.nsa_id || ''),
        'Original ID': getOriginalId(record.original_id, record.nsa_id) || '',
        'Annex Site': customFields.annexSite || '',
        'Annex Site #': customFields.annex_site_number || '',
        'MSA': customFields.msa || '',
        'Site Address': record.address || '',
        'City': record.city || '',
        'State': record.state || '',
        'County': customFields.county || '',
        'Zip Code': record.zip_code || '',
        'Direct Phone #': record.phone_number || '',
        'Store Cell Phone #': customFields.store_cell_phone || '',
        'Store Email Address': record.email_address || '',
        'West or East Division': customFields.divisionGeography || '',
        'Division #': record.division || '',
        'Division Director': customFields.division_director || '',
        'Region #': record.region || '',
        'Region Manager': customFields.regionalManager || '',
        'Regional Phone #': customFields.regionalManagerPhoneNumber || '',
        'Regional Email Address': customFields.regionalManagerEmail || '',
        'Area #': record.area || '',
        'Area Manager': customFields.areaManager || '',
        'AM Phone #': customFields.areaManagerPhoneNumber || '',
        'AM Email Address': customFields.areaManagerEmail || '',
        'Unit Count': customFields.units || '',
        'Commercial Lease Title Holder': customFields.commercial_Lease_Title_Holder || '',
        'Property Square Footage': customFields.totalSqFt || '',
        'Partnership': customFields.partnership || '',
        'Company Code': customFields.companyCode || '',
        'Entity Code': customFields.entityCode || '',
        'Acquisition Date': customFields.acquisitionDate || '',
        'Pricing Analyst': customFields.rmAnalyst || '',
        'People Ops Partner': customFields.people_Ops_Partner || '',
        'Recruiting Partner': customFields.recruitingPartner || '',
        'Accounting Partner': customFields.accounting_Partner || '',
        'Facilities Coordinator': customFields.facilities_coordinator || '',
        'Apartment on Site': customFields.apartmentOnSite || '',
        'Commercial Office Spaces': customFields.commercial_office_spaces || '',
        'Billboard': customFields.billboard || '',
        'Cell Tower': customFields.cell_tower || '',
        'SSM Conversion Date': customFields.ssm_conversion_date || '',
        'Unique Property Details': customFields.anythingUniqueAboutThisFacilityOrItsOfferings || '',
        'Lcode': customFields.Lcode || '',
        'Emergency Gate Code': customFields.emergencyGateCode || '',
        'Property Management System': customFields.propertyManagementSystem || '',
        'Complaint Contacts': customFields['pLEASESENDANYCOMPLAINTSTO:'] || ''
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Facilities');

    // Generate buffer
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Save to file
    const dataBlob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = URL.createObjectURL(dataBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'facilities.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <Card>
      <Space style={{ marginBottom: 16 }}>
        <Button
          type="primary"
          onClick={handleExport}
          icon={<DownloadOutlined />}
        >
          Export Excel
        </Button>
      </Space>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
                row: EditableRow,
                cell: EditableCell,
              },
          }}
        //   onRow={(record, rowIndex) => {
        //     return {
        //       onClick: () => {
        //         if (editingKey === '') {
        //           handleView(record);
        //         }
        //       },
        //     };
        //   }}
          dataSource={Array.isArray(biFacilities) ? biFacilities.map((item) => ({
            ...item,
            key: item.id,
          })) : []}          
          
          pagination={{
            onChange: cancel,
            pageSize: 50,
          }}
          columns={mergedColumns}
          loading={{ indicator: <Spin />, spinning: loading }}
          scroll={{
            x: 'max-content',
            y: 900,
          }}
          rowClassName="editable-row"
          bordered
        />
      </Form>
    </Card>
  );
};

export default OpsAlignmentFacilitiesTable;
